<template>
    <div class="DailySettleCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="detail" :inline="true" size="small" label-width="85px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="交易机构">
                            <el-input readonly v-model="detail.deptName" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="结算日期">
                            <el-date-picker
                                style="width: 200px"
                                v-model="detail.settleDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                readonly
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="收银员">
                            <el-input readonly v-model="detail.operatorName" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="detail.creator" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="充值人数">
                            <el-input readonly v-model="detail.totalRechargePeople" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="收取工本费">
                            <el-input readonly v-model="detail.inWorkCost" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="充值笔数">
                            <el-input readonly v-model="detail.totalRechargeCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="充值总额">
                            <el-input readonly v-model="detail.totalRechargeMoney" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款人数">
                            <el-input readonly v-model="detail.totalRefundPeople" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="支出工本费">
                            <el-input readonly v-model="detail.outWorkCost" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款笔数">
                            <el-input readonly v-model="detail.totalRefundCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="退款总额">
                            <el-input readonly v-model="detail.totalRefundMoney" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="detail.remark" />
                    </el-col>
                </el-row>
            </el-form>
            <el-button type="primary" @click="handleAudit" size="small">审核详情</el-button>
            <PickupReturnAudit
                ref="PickupReturnAudit"
                :review-privilege-flag="'menu.finance.member.dailySettle.review'"
            />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table border style="width: 100%" :data="detail.dailySettlePays">
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="支付类型" width="200" prop="templateName" />
                <el-table-column label="支付总额" width="144" prop="money">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.money | money }}</span></template
                    >
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import EfRemark from 'components/EfRemark';
import PickupReturnAudit from '../../../components/PickupReturnAudit';

export default {
    name: 'MemberDailySettleCreate',
    components: { EfRemark, PickupReturnAudit },
    props: ['form'],
    data() {
        return {
            baseUrl: '/finance/member/settle/daily',
            detail: [],
            url: {
                queryDetail: '/finance/member/settle/daily/info_c/',
            },
            meta: {
                types: [],
            },
        };
    },
    mounted() {
        const _this = this;
        _this.handleQuery();
    },
    methods: {
        handleAudit() {
            this.$refs.PickupReturnAudit.show(this.form.code, this.baseUrl);
        },
        handleQuery() {
            const _this = this;
            Util.queryTable(_this, _this.url.queryDetail + _this.form.code, null, (data) => {
                _this.detail = data.data;
                _this.detail.inWorkCost = _this.fmtMoney(data.data.inWorkCost);
                _this.detail.outWorkCost = _this.fmtMoney(data.data.outWorkCost);
                _this.detail.totalRechargeMoney = _this.fmtMoney(data.data.totalRechargeMoney);
                _this.detail.totalRefundMoney = _this.fmtMoney(data.data.totalRefundMoney);
            });
        },
    },
};
</script>

<style scoped>
.dailySettle_detail .el-form-item {
    margin-bottom: 0;
}
</style>
